<template>
    <div class="pt-4 login-tabs-container">
        <div class="mb-4">
            <h3 class="mb-4">Login</h3>
            <p>Welcome back, please login to your account.</p>
        </div>
        <div class="pt-2">
            <vs-input
                name="email"
                icon-no-border
                icon="email"
                label-placeholder="Email"
                v-model="email"
                size="large"
                class="w-100"/>
            <!-- <span class="text-danger text-sm">{{ errors.first('email') }}</span> -->

            <vs-input
                type="password"
                name="password"
                icon-no-border
                icon="lock"
                label-placeholder="Password"
                v-model="password"
                size="large"
                class="w-100 pt-4 pb-3" />
            <p class="text-center mt-4 mb-4 text-danger" v-if="status === 'error'">{{message}} </p>
            <vs-divider>OR</vs-divider>
            <div class="d-flex flex-wrap justify-content-center my-4">
                <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                <router-link to="/login/forgot_password">Forgot Password?</router-link>
            </div>
            <vs-button class="w-100" size="large" :disabled="!validateForm" @click="logUserIn">Login</vs-button>
            <span>Don't have an account? </span>
            <vs-button class="mt-4 font-weight-bold" type="flat" @click="registerUser">Start registration</vs-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    name: 'LoginComponent',
    data () {
        return {
            email: '',
            password: '',
            checkbox_remember_me: false,
        }
    },
    computed: {
        validateForm () {
            return /*!this.errors.any() && */this.email !== '' && this.password !== ''
        },
        ...mapState('auth', ['status', 'message'])
    },
    methods: {
        logUserIn () {
        // Loading
        this.$vs.loading();
        this.reset();
        const payload = {
            email: this.email,
            password: this.password,
            closeAnimation: this.$vs.loading.close,
            open: (requirePasswordChange) => requirePasswordChange ? this.$router.push('/login/change_password') : this.$router.push('/app')
        };
        //this.$store.dispatch('auth/login', payload);
        this.login(payload);
        },
        ...mapActions('auth', ['login']),
        ...mapMutations('auth', ['reset']),
        registerUser() {
            this.reset();
            this.$router.push('/register').catch(() => {});
        }
    }
}
</script>

<style lang="scss">
    .login-tabs-container {
    min-height: 505px;

    .con-tab {
        padding-bottom: 14px;
    }

    .con-slot-tabs {
        margin-top: 1rem;
    }
    }
</style>